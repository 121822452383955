<template>
    <div class="body">
        <div class="main">
        <div class="row  justify-content-md-center">
            <div class="col-3">
                <!--submit时触发register函数 并阻止默认行为:防止组件之间的向上或向下传递-->
                <form @submit.prevent="register"> 
                    <h3 class="title" style="text-align: center;">King Of Bots</h3>
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <!-- 使用v-model与定义的变量username绑定 -->
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <!-- 使用v-model与定义的变量password绑定 -->
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <div class="mb-3">
                        <label for="confirmedPassword" class="form-label">确认密码</label>
                        <!-- 使用v-model与定义的变量confirmedPassword绑定 -->
                        <input v-model="confirmedPassword" type="password" class="form-control" id="confirmedPassword" placeholder="请再次输入密码">
                    </div>
                    <!-- {{error_message}}显示变量error_message的值 -->
                    <div class="error_message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">注册</button>
                </form>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import $ from 'jquery'
// import ContentField from '../../../components/ContentField.vue'
import { ref } from 'vue'
import router from '../../../router/index'

export default {
    components: {
        // ContentField,
    },
    setup() {
        let username = ref('');
        let password = ref('');
        let confirmedPassword = ref('');
        let error_message = ref('');

        // 不会赋值所以用const
        const register = () => {
            error_message.value = ""
            $.ajax({
                url: "https://www.hanfei.fun/api/user/account/register/",
                // 会修改数据库就要用post,不会修改数据库就用get
                type: "post",
                data: {
                    username: username.value,
                    password: password.value,
                    confirmedPassword: confirmedPassword.value
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        router.push({name: "user_account_login"});
                    }else{
                       // console.log(resp.error_message);
                       error_message.value = resp.error_message;//如果注册失败, 显示错误信息
                    }
                }
            });
        }
        return {
            username,
            password,
            confirmedPassword,
            error_message,
            register,
        }
    }

}
</script>
<style scoped>
*,*::after,*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
}

.body {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 12vh;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    /* color: #a0a5a8; */
}

.main {
    position: relative;
    width: 1000px;
    min-width: 1000px;
    min-height: 600px;
    height: 600px;
    padding: 25px;
    padding-top: 8vh;
    background-color: rgba(50, 50, 50, 0.1);
    box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
    border-radius: 20px;
    overflow: hidden;

}

@media (max-width: 1200px) {
    .main {
        transform: scale(0.7);
    }
}

@media (max-width: 1000px) {
    .main {
        transform: scale(0.6);
    }
}

@media (max-width: 800px) {
    .main {
        transform: scale(0.5);
    }
}

@media (max-width: 600px) {
    .main {
        transform: scale(0.4);
    }
}

form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

label {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding-bottom: 2px;
}

input {
    width: 350px;
    height: 40px;
    margin: 4px 0;
    padding-left: 25px;
    font-size: 16px;
    letter-spacing: .15px;
    border: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    transition: .25s ease;
    border-radius: 8px;
    background-color: rgba(10, 10, 10, 0.05);
}

button {
    width: 180px;
    height: 50px;
    border-radius: 25px;
    margin-top: 30px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1.15px;
    color: rgba(255, 255, 255, 0.92);
}

div.error_message {
    font-size: 16px;
    color: rgb(224, 83, 83, 0.8);
    font-weight: bolder;
}

h3 {
    padding-bottom: 1vh;
}
</style>