import { GameObject } from "./GameObject";
import { Cell } from "./Cell";

export class Snake extends GameObject{
    constructor(info, gamemap){
        super();
        this.id = info.id; // id
        this.color = info.color; // 颜色
        this.gamemap = gamemap; // 调用函数和参数(单位格子的边长)

        // 存放蛇的身体，cells[0]存放蛇头，初始的时候只有一个点
        this.cells = [new Cell(info.r, info.c)];
        this.next_cell = null; //下一步的目标位置

        this.speed = 5; // 蛇每秒能走多少个格子

        // 存储蛇的移动方向
        this.direction = -1; // -1表示没有指令,0\1\2\3表示上下左右
        this.status = "idle"; // idle表示静止,move表示正在移动,die表示死亡

        this.dr = [-1,0,1,0]; // 表示四个方向行的偏移量
        this.dc = [0,1,0,-1]; // 表示四个方向列的偏移量

        this.step = 0; // 表示回合数

        this.eps = 1e-2;  // 允许的误差

        this.eye_direction = 0; // 初始时左下角的蛇头朝上
        if (this.id === 0) this.eye_direction = 0; // 左下角的蛇头初始化朝上
		if (this.id === 1) this.eye_direction = 2; // 右上角的蛇头初始化朝下
        // 不同方向蛇眼睛的偏移量
        this.eye_dx = [ // 蛇眼睛在x轴不同方向的偏移量
            [-1, 1], // 朝上
            [1, 1], // 朝右
            [1, -1], // 朝下
            [-1, -1], // 朝左
        ];
        this.eye_dy = [ // 蛇眼睛在y轴不同方向的偏移量
            [-1, -1], // 朝上
            [-1, 1], // 朝右
            [1, 1], // 朝下
            [1, -1], // 朝左
        ];
    }
    start(){
    }


    set_direction(d) {
        this.direction = d;
    }

    // 检测当前回合，蛇的长度是否增加
    check_tail_increasing() {
        // 一定变长一次
        if (this.step <= 10) return true;
        // 每三步长一次
        if (this.step % 3 === 1) return true;
        return false;
    }

    next_step(){
        // 将当前的方向取出来
        const d = this.direction;
        // 计算下一步的位置在什么地方，取出蛇头的位置并变化(行偏移量、列偏移量)
        this.next_cell = new Cell(this.cells[0].r+this.dr[d],this.cells[0].c+this.dc[d]);
        // console.log(this.next_cell);
        this.eye_direction = d;
        this.direction = -1; // 清空操作,用完这个方向了
        this.status = "move"; // 更变状态
        this.step ++;

        // 求长度,所有小球的数量
        const k = this.cells.length;
        // 初始元素不变 每一个元素往后移动一位 相当于头部多了一个复制体
        // 每个小球都向后移动一位
        for (let i = k; i > 0; i--) {
            // this.cells[i] = this.cells[i-1]; // 错误写法：赋的是引用
            // 深层复制一遍
            this.cells[i] = JSON.parse(JSON.stringify(this.cells[i - 1]));
        }
        // 下一步操作撞击了，蛇直接去世
        // if (!this.gamemap.check_valid(this.next_cell)) {
        //     this.status = "die";
        // }
    }

    update_move() {
        // 考虑一种一般情况 即目标与蛇头位置任意
        // 根据速度得出每帧移动多少距离=速度*时间(换算成秒)
        // this.cells[0].x += this.speed * this.timedelta/1000;
        const dx = this.next_cell.x - this.cells[0].x; // 求x的偏移量
        const dy = this.next_cell.y - this.cells[0].y; // 求y的偏移量
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < this.eps) {  // 走到目标点了
            this.cells[0] = this.next_cell;  // 添加一个新蛇头,将目标点作为新的蛇头
            this.next_cell = null; // 清空目标点
            this.status = "idle";  // 走完了，停下来
            // 如果走到目标点了且蛇没有变长时，要把尾巴砍掉
            if (!this.check_tail_increasing()) { // 蛇不变长
                this.cells.pop();
            }
        } else {
            // 每两帧之间移动的距离
            const move_distance = this.speed * this.timedelta / 1000;
            this.cells[0].x += move_distance * dx / distance;
            this.cells[0].y += move_distance * dy / distance;
            // 如果蛇尾不变长的话，蛇尾就需要进行移动，走到下一个目的地
            if (!this.check_tail_increasing()) {
                const k = this.cells.length; // 先取出蛇的长度
                // 取出蛇尾和蛇尾的目标位置，将tail移动到tail_target
                const tail = this.cells[k - 1], tail_target = this.cells[k - 2];
                const tail_dx = tail_target.x - tail.x;
                const tail_dy = tail_target.y - tail.y;
                // 移动，蛇头蛇尾移动距离一样的(每帧也是一样的)
                tail.x += move_distance * tail_dx / distance;
                tail.y += move_distance * tail_dy / distance;
            }
        }
    }

    update(){
        if (this.status === 'move') {
            this.update_move(); // 执行单单元移动
        }
        this.render();
    }
    render() {
        // 取出单元格边长
        const L = this.gamemap.L;
        // 取出画布引用
        const ctx = this.gamemap.ctx;

        ctx.fillStyle = this.color; // 画画颜色

        // 当蛇死亡的时候，变成惨白的颜色
        if (this.status === "die") {
            ctx.fillStyle = "white";
        }

        // 画一个圆, of表示取出对象
        for(const cell of this.cells){
            ctx.beginPath(); // 开启一个路径
            // 圆心坐标，园的半径，起始角度0和终止角度2PAI
            // 2. 让蛇更瘦,对所有的L都乘一个0.8
            ctx.arc(cell.x*L, cell.y*L, L/2*0.8, 0, Math.PI*2);
            ctx.fill();
        }

        // 蛇的美化
        // 1. 让蛇形体更连贯
        for (let i = 1; i < this.cells.length; i ++ ) {
            // 记录相邻的两条蛇
            const a = this.cells[i - 1], b = this.cells[i];
            // 如果两个小球已经重合就跳过
            if (Math.abs(a.x - b.x) < this.eps && Math.abs(a.y - b.y) < this.eps)
                continue;
            if (Math.abs(a.x - b.x) < this.eps) {
                // 竖方向,左上角坐标与x长y宽
                // a.x - 0.4 = a.x - 0.5 + 0.1 加百分之10的偏移量
                ctx.fillRect((a.x - 0.4) * L, Math.min(a.y, b.y) * L, L * 0.8, Math.abs(a.y - b.y) * L);
            } else {
                // 横方向,左上角坐标与x长y宽
                ctx.fillRect(Math.min(a.x, b.x) * L, (a.y - 0.4) * L, Math.abs(a.x - b.x) * L, L * 0.8);
            }
        }

        // 蛇的眼睛
        ctx.fillStyle = "black"; // 黑色眼睛
        for (let i = 0; i < 2; i ++ ) {
            // 眼睛坐标：蛇头的中心点坐标+一个偏移量，变成相对距离
            // 半径时0.5, 偏移的量要控制一下
            const eye_x = (this.cells[0].x + this.eye_dx[this.eye_direction][i] * 0.15) * L;
            const eye_y = (this.cells[0].y + this.eye_dy[this.eye_direction][i] * 0.15) * L;

            ctx.beginPath(); // 画两个圆
            // 圆心, 半径随便搞一个, 0到2PAI
            ctx.arc(eye_x, eye_y, L * 0.05, 0, Math.PI * 2);
            ctx.fill(); // 画出两个圆
        }
    }
}