<template>
    <div class="body">
        <div class="main" v-if="!$store.state.user.pulling_info">
        <div class="row  justify-content-md-center">
            <div class="col-3">
                <h3 class="title" style="text-align: center;">King Of Bots</h3>
                <!--submit时触发login函数 并阻止默认行为:防止组件之间的向上或向下传递-->
                <form @submit.prevent="login"> 
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <!-- 使用v-model与定义的变量username绑定 -->
                        <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <!-- 使用v-model与定义的变量password绑定 -->
                        <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                    </div>
                    <!-- {{error_message}}显示变量error_message的值 -->
                    <div class="error_message">{{ error_message }}</div>
                    <button type="submit" class="btn btn-primary">登录</button>
                </form>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
// import ContentField from '../../../components/ContentField.vue'
import { useStore } from 'vuex' //全局变量
import { ref } from 'vue';//所有变量的定义借助ref表示
import router from '../../../router/index';//如果登录成功 跳转到相应的路由页面
export default {
    components: {
        // ContentField,
    },
    setup(){
        const store = useStore();//取出全局变量
        let username = ref("");//定义username 初始为空
        let password = ref("");//定义password
        let error_message = ref("");//表示是否成功登录
        // const jwt_token = localStorage.getItem("jwt_token");
        // if(jwt_token){//若jwt_token存在
        //     store.commit("updateToken",jwt_token);//将token更新为jwt_token
        //     //判断jwt是否有效
        //     store.dispatch("getinfo", {
        //         success(){
        //             router.push({name:"home"});
        //             store.commit("updatePullingInfo", false);//拉取结束
        //         },
        //         error(){
        //             store.commit("updatePullingInfo", false);
        //         }
        //     })
        // }else{
        //     store.commit("updatePullingInfo", false);
        // }
        const login = () => { //定义login函数 当页面提交时触发
            error_message.value = "";// 错误信息清空
            store.dispatch("login",{//使用dispatch来调用store\user.js中action中函数
                username:username.value,//ref变量取值使用value
                password:password.value,
                success(){
                    //console.log(resp);
                    store.dispatch("getinfo",{
                        success(){
                            router.push({name:'home'});
                            //这里的user就是在store/index中导入的user: ModuleUser
                            // console.log(store.state.user);
                            //console.log(store.state);  
                        }
                    })
                },
                error(){//如果登录失败，给用户返回一个提示
                    error_message.value = "用户名或密码错误";
                }
            })
        }
        return{
            username,
            password,
            error_message,
            login,
        }
    }
}
</script>
<style scoped>
*,*::after,*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
}

.body {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 12vh;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    /* color: #a0a5a8; */
}

.main {
    position: relative;
    width: 1000px;
    min-width: 1000px;
    min-height: 600px;
    height: 600px;
    padding: 25px;
    padding-top: 10vh;
    background-color: rgba(50, 50, 50, 0.1);
    box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
    border-radius: 20px;
    overflow: hidden;

}

@media (max-width: 1200px) {
    .main {
        transform: scale(0.7);
    }
}

@media (max-width: 1000px) {
    .main {
        transform: scale(0.6);
    }
}

@media (max-width: 800px) {
    .main {
        transform: scale(0.5);
    }
}

@media (max-width: 600px) {
    .main {
        transform: scale(0.4);
    }
}

form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

label {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding-bottom: 2px;
}

input {
    width: 350px;
    height: 40px;
    margin: 4px 0;
    padding-left: 25px;
    font-size: 16px;
    letter-spacing: .15px;
    border: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    transition: .25s ease;
    border-radius: 8px;
    background-color: rgba(10, 10, 10, 0.05);
}

button {
    width: 180px;
    height: 50px;
    border-radius: 25px;
    margin-top: 30px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1.15px;
    color: rgba(255, 255, 255, 0.92);
}

div.error_message {
    font-size: 16px;
    color: rgb(224, 83, 83, 0.8);
    font-weight: bolder;
}

h3 {
    padding-bottom: 1vh;
}
</style>