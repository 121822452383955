<template lang="">
    <div class="matchground">
        <div class="row">
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.user.photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.user.username }}
                </div>
            </div>
            <div class="col-4">
                <div class="user-select-bot">
                    <select v-model="select_bot" class="form-select" aria-label="Default select example">
                        <option value="-1" selected>亲自上阵</option>
                        <option v-for="bot in bots" :key="bot.id" :value="bot.id">
                            {{ bot.title }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-4">
                <div class="user-photo">
                    <img :src="$store.state.pk.opponent_photo" alt="">
                </div>
                <div class="user-username">
                    {{ $store.state.pk.opponent_username }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="text-align:center; padding-top:12vh">
                    <button @click="click_match_btn" class="btn btn-warning btn-lg">{{match_btn_info}}</button>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex';
import $ from 'jquery'

export default {
    setup(){
        const store = useStore();
        let match_btn_info = ref("开始匹配");
        // bots用于保存getlist返回的bot列表
        let bots = ref([]);
        let select_bot = ref("-1");
        // 定义函数
        const refresh_bots = () => {
            $.ajax({
                url:"https://www.hanfei.fun/api/user/bot/getlist/",
                type:"GET",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bots.value = resp;
                }
            })
        }
        // 调用函数
        refresh_bots();

        const click_match_btn = () =>{
            if(match_btn_info.value === "开始匹配"){
                // console.log(select_bot.value);
                match_btn_info.value = "取消";
                // console.log(store.state.pk.socket)
                store.state.pk.socket.send(JSON.stringify({
                    event:"start-matching",
                    bot_id: select_bot.value,
                }));
            }else{
                match_btn_info.value = "开始匹配";
                store.state.pk.socket.send(JSON.stringify({
                    event:"stop-matching",
                }));
            }
        };

        return{
            match_btn_info,click_match_btn,bots,select_bot
        }
    }
    
}
</script>
<style scoped>

div.matchground{
    width: 60vw;
    height: 70vh;
    background-color: rgba(50, 50, 50, 0.5);
    margin: 0 auto;
    margin-top: 50px;
}
div.user-photo{
    text-align: center;
    padding-top: 10vh;
}
div.user-photo > img{
    border-radius: 50%;
    width: 20vh;
}
div.user-username{
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    color: white;
    padding-top: 2vh;
}
div.user-select-bot{
    padding-top: 20vh;
}
div.user-select-bot > select{
    width: 60%;
    margin: 0 auto;
    background-color: rgba(60, 60, 60, 0.1);
    color: white;
}
div.user-select-bot > select > option{
    background-color: rgba(50, 50, 50, 0.3);
}
</style>