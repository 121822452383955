<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card">
                    <div class="card-body">
                        <img :src="$store.state.user.photo" style="width:100%">
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card">
                    <div class="card-header">
                        <span style="font-size:130%">我的Bot</span>
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal"
                            data-bs-target="#add-bot-btn">
                            创建
                        </button>

                        <!-- Modal -->
                        <div class="modal fade" id="add-bot-btn" tabindex="-1" aria-labelledby="add-bot-btnLabel"
                            aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="add-bot-btnLabel">创建 Bot </h1>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <!-- 更改example名称、添加placehold默认字段、修改所占行数rows -->
                                    <div class="modal-body">
                                        <!-- 请输入Bot名称 -->
                                        <div class="mb-3">
                                            <label for="add-bot-title" class="form-label">名称</label>
                                            <input v-model="botadd.title" type="text" class="form-control" id="add-bot-title"
                                                placeholder="请输入Bot名称">
                                        </div>
                                        <!-- 请输入Bot简介 -->
                                        <div class="mb-3">
                                            <label for="add-bot-description" class="form-label">简介</label>
                                            <textarea v-model="botadd.description" class="form-control" id="add-bot-description" rows="3"
                                                placeholder="请输入Bot简介"></textarea>
                                        </div>
                                        <!-- 请输入Bot代码 -->
                                        <div class="mb-3">
                                            <label for="add-bot-code" class="form-label">代码</label>
                                            <!-- <textarea v-model="botadd.content" class="form-control" id="add-bot-code" rows="7"
                                                placeholder="请输入Bot代码"></textarea> -->
                                            <VAceEditor @init="editorInit" v-model:value="botadd.content" :options="{
                                                enableBasicAutocompletion: true,
                                                enableSnippets: true,
                                                enableLiveAutocompletion: true,
                                                fontSize: 18,
                                                tabSize: 4,
                                                showPrintMargin: false,
                                                highlightActiveLine: true,
                                            }" lang="java"
                                                theme="textmate" style="height: 400px" />
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <div class="error-message">{{ botadd.error_message }}</div>
                                        <button type="button" class="btn btn-primary"
                                            @click="add_bot">创建</button>
                                        <button type="button" class="btn btn-secondary"
                                            data-bs-dismiss="modal">取消</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">名称</th>
                                    <th scope="col">创建时间</th>
                                    <th scope="col">操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bot in bots" :key="bot.id">
                                    <td>{{ bot.title }}</td>
                                    <td>{{ bot.createtime }}</td>
                                    <td>
                                        <button type="button" class="btn btn-secondary" style="margin-right: 10px;" data-bs-toggle="modal" :data-bs-target="'#update-bot-btn-' + bot.id">修改</button>
                                        <button type="button" class="btn btn-danger" @click="remove_bot(bot)">删除</button>
                                        <!-- Modal -->
                                        <div class="modal fade" :id="'update-bot-btn-' + bot.id" tabindex="-1" aria-labelledby="update-bot-btnLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="update-bot-btnLabel">修改 Bot </h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <!-- 更改example名称、添加placehold默认字段、修改所占行数rows -->
                                                    <div class="modal-body">
                                                        <!-- 请输入Bot名称 -->
                                                        <div class="mb-3">
                                                            <label for="add-bot-title" class="form-label">名称</label>
                                                            <input v-model="bot.title" type="text" class="form-control" id="add-bot-title"
                                                                placeholder="请输入Bot名称">
                                                        </div>
                                                        <!-- 请输入Bot简介 -->
                                                        <div class="mb-3">
                                                            <label for="add-bot-description" class="form-label">简介</label>
                                                            <textarea v-model="bot.description" class="form-control" id="add-bot-description" rows="3"
                                                                placeholder="请输入Bot简介"></textarea>
                                                        </div>
                                                        <!-- 请输入Bot代码 -->
                                                        <div class="mb-3">
                                                            <label for="add-bot-code" class="form-label">代码</label>
                                                            <!-- <textarea v-model="bot.content" class="form-control" id="add-bot-code" rows="7"
                                                                placeholder="请输入Bot代码"></textarea> -->
                                                            <!-- <VAceEditor
                                                                v-model:value="bot.content"
                                                                @init="editorInit"
                                                                lang="c-cpp"
                                                                theme="textmate"
                                                                style="height: 300px" /> -->
                                                            <VAceEditor @init="editorInit" v-model:value="bot.content" :options="{
                                                                enableBasicAutocompletion: true,
                                                                enableSnippets: true,
                                                                enableLiveAutocompletion: true,
                                                                fontSize: 18,
                                                                tabSize: 4,
                                                                showPrintMargin: false,
                                                                highlightActiveLine: true,
                                                            }" lang="java"
                                                                theme="textmate" style="height: 400px" />
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div class="error-message">{{ bot.error_message }}</div>
                                                        <button type="button" class="btn btn-primary"
                                                            @click="update_bot(bot)">保存修改</button>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal">取消</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import $ from 'jquery'
import { reactive } from 'vue'
import { Modal } from 'bootstrap/dist/js/bootstrap'
import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';
import '@/assets/config/ace.config' // 主要是引入这个前面写的配置文件

export default {
    name: "UserBotIndexView",
    components: {
        VAceEditor,
    },
    setup(){
        ace.config.set(
            "basePath",
            "https://cdn.jsdelivr.net/npm/ace-builds@" + require('ace-builds').version + "/src-noconflict/")
        const store = useStore();
        // bots用于保存getlist返回的bot列表
        let bots = ref([]);
        // 定义一个Bot对象在Modal中显示
        const botadd = reactive({
            title:"",
            description:"",
            content:"",
            error_message:"",
        });
        function editorInit() {
            require("ace-builds/src-noconflict/ext-language_tools");
            require("ace-builds/src-noconflict/snippets/sql");
            require("ace-builds/src-noconflict/snippets/c_cpp");
            require("ace-builds/src-noconflict/snippets/java");
            require("ace-builds/src-noconflict/mode-c_cpp");
            require("ace-builds/src-noconflict/mode-java");
            require("ace-builds/src-noconflict/theme-monokai"); //
            require("ace-builds/src-noconflict/mode-html");
            require("ace-builds/src-noconflict/mode-html_elixir");
            require("ace-builds/src-noconflict/mode-html_ruby");
            require("ace-builds/src-noconflict/mode-javascript");
            require("ace-builds/src-noconflict/mode-python");
            require("ace-builds/src-noconflict/snippets/less");
            require("ace-builds/src-noconflict/theme-chrome"); //
            require("ace-builds/src-noconflict/ext-static_highlight");
            require("ace-builds/src-noconflict/ext-beautify");
        }

        // 定义函数
        const refresh_bots = () => {
            $.ajax({
                url:"https://www.hanfei.fun/api/user/bot/getlist/",
                type:"GET",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bots.value = resp;
                }
            })
        }
        // 调用函数
        refresh_bots();
        
        const add_bot = () => {
            botadd.error_message = "";
            $.ajax({
                // 从App.vue中复制之前测试用的代码
                url: "https://www.hanfei.fun/api/user/bot/add/",
                type: "post",
                data: {
                    title: botadd.title,
                    description: botadd.description,
                    content: botadd.content,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // console.log(resp);
                    if (resp.error_message === "success") {
                        botadd.title = "";
                        botadd.description = "";
                        botadd.content = "";
                        // 关闭悬浮窗，此处为Modal的id
                        // <!-- Modal -->
                        // <div class="modal fade" id="add-bot-btn"
                        Modal.getInstance("#add-bot-btn").hide();
                        refresh_bots();
                    } else {
                        botadd.error_message = resp.error_message;
                    }
                }
            })
        }

        const remove_bot = (bot)=>{
            $.ajax({
                url:"https://www.hanfei.fun/api/user/bot/remove/",
                type:"post",
                headers:{
                    Authorization:"Bearer " + store.state.user.token,
                },
                data:{
                    bot_id:bot.id
                },
                success(resp){
                    if(resp.error_message == "success"){
                        // console.log("删除成功");
                        refresh_bots();
                    }else{
                        alert(resp.error_message)
                    }
                }
            })
        }

        const update_bot = (bot) => {
            bot.error_message = "";
            $.ajax({
                url: "https://www.hanfei.fun/api/user/bot/update/",
                type: "post",
                data: {
                    bot_id:bot.id,
                    title: bot.title,
                    description: bot.description,
                    content: bot.content,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        Modal.getInstance('#update-bot-btn-' + bot.id).hide();
                        alert("修改成功");
                        refresh_bots();
                    } else {
                        bot.error_message = resp.error_message;
                    }
                }
            })
        }

        return{
            bots,botadd,add_bot,remove_bot,update_bot,editorInit
        }
    }
}
</script>

<style scoped>
div.card{
    margin-top: 20px;
}
.fade{
    --bs-modal-width: 700px;
}
th{
    vertical-align:middle;
    text-align: center;
}
td{
    vertical-align:middle;
    text-align: center;
}
</style>