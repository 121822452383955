<template>
    <ContentFieldVue>
        <table class="table table-striped table-hover" style="text-align:center">
            <thead>
                <tr>
                    <th>玩家</th>
                    <th>天梯积分</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td>
                        <img :src="user.photo" alt="" class="ranklist-user-photo">
                        &nbsp;
                        <span class="ranklist-user-username" > {{ user.username }}</span>
                    </td>
                    <td style="vertical-align:middle;">
                        {{ user.rating }}
                    </td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="...">
            <ul class="pagination" style="float:right">
                <li class="page-item" @click="click_page(-2)">
                    <a class="page-link" href="#" >前一页</a>
                </li>
                <li :class="'page-item ' + page.is_active" 
                    v-for="page in pages" :key="page.pageId" @click="click_page(page.pageId)">
                    <a class="page-link" href="#">{{page.pageId}}</a>
                </li>
                <li class="page-item" @click="click_page(-1)">
                    <a class="page-link" href="#">后一页</a>
                </li>
            </ul>
        </nav>
    </ContentFieldVue>
</template>
<script>
import ContentFieldVue from '../../components/ContentField.vue'
import { useStore } from 'vuex';
import $ from 'jquery'
import { ref } from 'vue';
export default {
    components: {
        ContentFieldVue
    },
    setup() {
        const store = useStore();
        let current_page = 1;
        let users = ref([]);
        let total_users = 0;
        let pages = ref([]);
        const click_page = page => {
            if(page === -2)
                page = current_page - 1;
            if(page === -1)
                page = current_page + 1;
            let max_pages = parseInt(Math.ceil(total_users / 10));
            if(page >= 1 && page <= max_pages){
                pull_page(page);//加载一个新的分页
            }
        }
        const update_pages = () =>{
            let max_pages = parseInt(Math.ceil(total_users / 10));//最大页数
            let new_pages = [];
            for(let i = current_page - 2; i <= current_page + 2; i++){
                if(i >= 1 && i <= max_pages){
                    new_pages.push({
                        pageId:i,
                        is_active:i === current_page ? "active" : ""
                    });
                }
            }   
            pages.value = new_pages;
        }
        const pull_page = page => {
            current_page = page;
            $.ajax({
                url: "https://www.hanfei.fun/api/ranklist/getlist/",
                data: {
                    page,
                },
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // console.log(resp);
                    users.value = resp.users;
                    total_users = resp.user_count;
                    update_pages();
                },
                error(resp) {
                    console.log(resp);
                }
            })
        }
        pull_page(current_page);
        return {
            users,
            total_users,
            pages,
            click_page
        }
    }
}
</script>
<style scoped>
img.ranklist-user-photo {
    width: 6vh;
    border-radius: 50%;
}
</style>