<template>
    <PlayGround v-if="$store.state.pk.status === 'playing'"></PlayGround>
    <MatchGround v-if="$store.state.pk.status === 'matching'"></MatchGround>
    <ResultBoard v-if="$store.state.pk.loser !== 'none'"></ResultBoard>
</template>
<script>
import PlayGround from '../../components/PlayGround.vue'
import MatchGround from '../../components/MatchGround.vue'
import ResultBoard from '../../components/ResultBoard.vue'
import { onMounted,onUnmounted } from 'vue'
import { useStore } from 'vuex'

export default {
    components:{
        PlayGround,
        MatchGround,
        ResultBoard,
    },
    setup(){
        const store = useStore();
        // console.log(store.state.user.id);
        // 协议从http变成ws，注意是分号`而不是单引号'
        // const socketUrl = `ws://127.0.0.1:3000/websocket/${store.state.user.id}`;
        const socketUrl = `wss://www.hanfei.fun/websocket/${store.state.user.token}`;
        // 添加一个状态更新
        store.commit("updateLoser","none");
        // 表明不是录像
        store.commit("updateIsRecord", false);
        let socket = null;
        // 挂载后
        onMounted(() => {
            // 对手假数据
            store.commit("updateOpponent",{
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            })
            // 创建链接
            socket = new WebSocket(socketUrl);
            // 如果连接成功，将socket存储到全局变量中
            socket.onopen = () => {
                console.log("connected!");
                store.commit("updateSocket",socket);
            }
            // 当接收到信息的时候，msg中会有数据，spring定义的
            socket.onmessage = msg =>{
                const data = JSON.parse(msg.data);
                // console.log(data);
                // 如果事件是开始匹配
                if(data.event === "start-matching"){
                    if(parseInt(data.game.a_id) === -2 && parseInt(data.game.b_id) === parseInt(store.state.user.id)){
                        data.opponent_username = "AFEI";
                        data.opponent_photo = "https://cdn.acwing.com/media/user/profile/photo/389257_lg_63350070b9.jpg";
                    }  
                    if(parseInt(data.game.b_id) === -2 && parseInt(data.game.a_id) === parseInt(store.state.user.id)){
                        data.opponent_username = "AFEI";
                        data.opponent_photo = "https://cdn.acwing.com/media/user/profile/photo/389257_lg_63350070b9.jpg";
                    }
                    store.commit("updateOpponent",{
                        username:data.opponent_username,
                        photo:data.opponent_photo,
                    });
                    // store.commit("updateStatus","playing")
                    // 匹配成功后，延时2秒进入对战页面
                    setTimeout(() => {
                        store.commit("updateStatus","playing")
                    }, 1000);
                    // 更新地图
                    // store.commit("updateGamemap",data.gamemap)
                    if(parseInt(data.game.a_id) === parseInt(store.state.user.id)){
                        store.state.pk.is_me = true;
                    }else{
                        store.state.pk.is_me = false;
                    }
                    
                    store.commit("updateGame",data.game)
                }else if(data.event === "move"){
                    // console.log(data);
                    // 如果事件是游戏中的移动
                    // console.log(store.state);
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;
                    // a&b_direction是由后端Game.sendMove()中广播的
                    snake0.set_direction(data.a_direction);
                    snake1.set_direction(data.b_direction);
                }else if(data.event === "result"){
                    // 如果事件是游戏结束后的结果
                    // console.log(data);
                    // 取出两条蛇
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;
                    if(data.loser === "all" || data.loser === "A"){
                        snake0.status = "die";
                    }
                    if(data.loser === "all" || data.loser === "B"){
                        snake1.status = "die";
                    }
                    // 在收到后端播报结果时，更新全局变量中的loser
                    store.commit("updateLoser", data.loser);
                }
            }
            // 断开连接后做的操作
            socket.onclose = () =>{
                console.log("disconnected!");
            }
        });
        // 界面被卸载关闭的时候要断开连接
        onUnmounted(()=>{
            socket.close();
            store.commit("updateStatus","waiting");
            
            location.reload();
        })
    }
}
</script>
<style lang="">
    
</style>