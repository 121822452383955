<template>
  <NavBar/>
  <router-view></router-view>
</template>
<script>
// 导入组件
import NavBar from './components/NavBar.vue'
// 导入bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"
// import $ from 'jquery';
// import store from "./store/index"

export default{
  components:{
    NavBar
  },
  // setup(){
  //   $.ajax({
  //     url:"http://localhost:3000/user/account/token/",
  //     type:"POST",
  //     data:{
  //       username:"feifei334",
  //       password:"feifei334",
  //     },
  //     success(resp){
  //       console.log(resp);
  //     },
  //     error(resp){
  //       console.log(resp);
  //     }
  //   });
  //   $.ajax({
  //     url:"http://localhost:3000/user/account/info/",
  //     type:"GET",
  //     headers:{
  //       // Authentication 授权
  //       // Authorization 认证
  //       Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI0ZmNmYTIxZDdmOGQ0OWIzYmI3ODNkNDNlYjQ5NzQ0YSIsInN1YiI6IjIiLCJpc3MiOiJzZyIsImlhdCI6MTcwOTYyNjgzMSwiZXhwIjoxNzEwODM2NDMxfQ.YecDSVcy9D11oGGUQJe_KL1TAlB710N6k8z3WTwzF28"
  //     },
  //     success(resp){
  //       console.log(resp);
  //     },
  //     error(resp){
  //       console.log(resp);
  //     }
  //   });
  //   $.ajax({
  //     url:"http://localhost:3000/user/account/register/",
  //     type:"POST",
  //     data:{
  //       username:"feifei3343",
  //       password:"feifei3343",
  //       confirmedPassword:"feifei3343",
  //     },
  //     success(resp){
  //       console.log(resp);
  //     },
  //     error(resp){
  //       console.log(resp);
  //     }
  //   });
  // }
  setup(){
    // $.ajax({
    //   url: "http://127.0.0.1:3000/user/bot/add/",
    //   type: "post",
    //   data: {
    //     title: "Bot的标题11",
    //     description: "Bot的描述11",
    //     content: "Bot的代码11",
    //   },
    //   headers: {
    //     Authorization: "Bearer " + store.state.user.token,
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // })
    // $.ajax({
    //   url: "http://127.0.0.1:3000/user/bot/remove/",
    //   type: "POST",
    //   data: {
    //     bot_id: 3, //可以修改为自己的bot_id
    //   },
    //   headers: {
    //     Authorization: "Bearer " + store.state.user.token,
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // })
    // $.ajax({
    //   url: "http://127.0.0.1:3000/user/bot/update/",
    //   type: "POST",
    //   data: {
    //     bot_id: 4,
    //     title: "我是Bot_4的标题",
    //     description : "我是Bot_4的描述",
    //     content: "我是Bot_4的代码",
    //   },
    //   headers: {
    //     Authorization: "Bearer " + store.state.user.token, 
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   } 
    // })
    // $.ajax({
    //   url: "http://127.0.0.1:3000/user/bot/getlist/",
    //   type: "get",
    //   headers: {
    //     Authorization: "Bearer " + store.state.user.token,
    //   },
    //   success(resp) {
    //     console.log(resp);
    //   },
    //   error(resp) {
    //     console.log(resp);
    //   }
    // })
  }
  
}
</script>
<style>
body{
  background-image: url("./assets/images/background.jpg");
  background-size: cover;
}
</style>