import { GameObject } from "./GameObject";
import { Snake } from "./Snack";
import {Wall} from "./Wall"

export class GameMap extends GameObject{
    constructor(ctx, parent, store){
        super();//若继承基类，则必须要有这句
        
        this.ctx = ctx;
        this.parent = parent;
        this.store = store;
        
        this.L = 0; // 绝对距离，一个小格子的边长
        this.rows = 13; // 行
        this.cols = 14; // 列

        this.inner_walls_count = 20;//定义内部障碍物数量

        this.walls = [];//用于保存障碍物

        this.snakes = [
            new Snake({id:0, color:"#4876EC",r:this.rows-2,c:1},this),
            new Snake({id:1, color:"#F94848",r:1,c:this.cols-2},this),
        ];

    }
    // // 判断连通性
    // // 参考题目:蛇形矩阵
    // check_connectivity(g, sx, sy, tx, ty){
    //     if (sx == tx && sy == ty) return true;
    //     g[sx][sy] = true;
    //     let dx = [-1, 0, 1, 0];
    //     let dy = [0, 1, 0, -1];
    //     for(let i = 0; i < 4; i++){
    //         // 当前方向的下一个坐标
    //         let x = sx + dx[i];
    //         let y = sy + dy[i];
    //         // 判断一下有没有撞墙并且可以搜到终点
    //         if(!g[x][y] && this.check_connectivity(g, x, y, tx, ty))
    //             return true;
    //     }
    //     // 如果搜不到终点
    //     return false;
    // }
    // //创建障碍物
    // create_walls(){
	// 	// new Wall(0,0,this);
    //     const g = [];
    //     //用bool数组来保存是否存在障碍物 初始值为false
    //     for(let r = 0; r < this.rows; r++){
    //         g[r] = [];
    //         for(let c = 0; c < this.cols; c++){
    //             g[r][c] = false;
    //         }
    //     }
    //     //给四周加上障碍物
    //     for(let r = 0; r < this.rows; r++){//给左右两侧设置为true
    //         g[r][0]=true;
    //         g[r][this.cols-1]=true;
    //     }
    //     for(let c = 0; c < this.cols; c++){//给上下两侧设置为true
    //         g[0][c] = g[this.rows-1][c] = true;
    //     }

    //     //在内部设置inner_walls_count个对称的障碍物
    //     for(let i = 0; i < this.inner_walls_count / 2; i++){
    //         for (let j = 0; j < 1000; j++) {
    //             // 找到两个随机值
    //             let r = parseInt(Math.random()*this.rows);
    //             let c = parseInt(Math.random()*this.cols);
    //             if(g[r][c] || g[this.rows-1-r][this.cols-1-c]) continue;//直接进入下一轮循环 j++
    //             // 
    //             if(r == this.rows - 2 && c == 1 || r == 1 && c == this.cols-2)
    //                 continue;//保证左下角和右上角不能有障碍物
    //             //成功设置一个障碍物后 直接退出当前for i++
    //             g[r][c] = g[this.rows-1-r][this.cols-1-c] = true;
    //             break;
    //         }
    //     }
    //     //需要将状态g的副本传入check_connectivity
    //     //深度拷贝一个对象:先转换成json再将json解析出来 就一定是一个新的东西
    //     const copy_g = JSON.parse(JSON.stringify(g));
    //     //如果地图不连通
    //     //传入g以及起点和终点的横纵坐标
    //     if(!this.check_connectivity(copy_g,this.rows-2,1,1,this.cols-2)) 
    //         return false;
    //     // console.log(g);
    //     //创建障碍物对象 并添加到this.walls数组
    //     for(let r = 0; r < this.rows; r++){
    //         for(let c = 0; c < this.cols; c++){
    //             if(g[r][c]){
    //                 this.walls.push(new Wall (r,c,this));
    //             }
    //         }
    //     }
    //     return true;//如果连通 
    // }
    //画地图:创建障碍物
    create_walls(){
        //直接将地图取出--后端传过来
        // console.log(this.store)
        const g = this.store.state.pk.gamemap;
        //创建障碍物对象 并添加到this.walls数组
        for(let r = 0; r < this.rows; r++){
            for(let c = 0; c < this.cols; c++){
                if(g[r][c]){
                    this.walls.push(new Wall (r,c,this));
                }
            }
        }
    }


    // add_listening_events() {
    //     this.ctx.canvas.focus();//聚焦
        
    //     // 取出两条蛇
    //     const [snake0, snake1] = this.snakes;
    //     this.ctx.canvas.addEventListener("keydown", e => {
    //         if (e.key === 'w') snake0.set_direction(0);
    //         else if (e.key === 'd') snake0.set_direction(1);
    //         else if (e.key === 's') snake0.set_direction(2);
    //         else if (e.key === 'a') snake0.set_direction(3);
    //         else if (e.key === 'ArrowUp') snake1.set_direction(0);
    //         else if (e.key === 'ArrowRight') snake1.set_direction(1);
    //         else if (e.key === 'ArrowDown') snake1.set_direction(2);
    //         else if (e.key === 'ArrowLeft') snake1.set_direction(3);
    //         console.log(e.key);
    //     });
    // }
    add_listening_events(){
        if (this.store.state.record.is_record) {
            let k = 0;
            const a_steps = this.store.state.record.a_steps;
            const b_steps = this.store.state.record.b_steps;
            const loser = this.store.state.record.record_loser;
            const [snake0, snake1] = this.snakes;
            const interval_id = setInterval(() => {
                if(k >= a_steps.length-1){
                    if(loser === "all" || loser === "A"){
                        snake0.status = "die";
                    }
                    if(loser === "all" || loser === "B"){
                        snake1.status = "die";
                    }
                    clearInterval(interval_id);
                }else{
                    snake0.set_direction(parseInt(a_steps[k]));
                    snake1.set_direction(parseInt(b_steps[k]));
                }
                k++;
           }, 300);//300ms执行一次
        } else {
            this.ctx.canvas.focus();//聚焦

            // const [snake0, snake1] = this.snakes;
            this.ctx.canvas.addEventListener("keydown",e=>{
                // console.log(e.key);
                //wasd控制移动
                let d = -1;
                if(e.key === 'w') d = 0;
                else if (e.key === 'd') d = 1;
                else if (e.key === 's') d = 2;
                else if (e.key === 'a') d = 3;

                if(d >= 0){//有效输入
                    // 发送移动请求
                    this.store.state.pk.socket.send(JSON.stringify({//将JSON转换为字符串
                        event:"move",
                        direction:d,
                    }))
                }
            });
        }
    }

    start(){
        // // this.create_walls();
        // // 随机1000次去找能连通的图
        // for (let i = 0; i < 1000; i++) {
        //     if(this.create_walls())
        //     break;
        // }
        this.create_walls();
        this.add_listening_events();
    }
    update_size() {
        // 宽对应的是列，长对应的是行
        // 转换成int保证没有缝
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
        // 小正方形的边长×列数
        this.ctx.canvas.width = this.L * this.cols;
        // 小正方形的边长×行数
        this.ctx.canvas.height = this.L * this.rows;
    }

    // 裁判：判断两条蛇是否都准备好了下一回合
    check_ready() {
        // 枚举每一条蛇
        for (const snake of this.snakes) {
            // js中判断是否相等要多一个=号
            // 如果蛇的状态不是静止的
            if (snake.status !== "idle") return false;
            // 如果蛇还没接收到下一条指令
            if (snake.direction === -1) return false;
        }
        return true;
    }
    next_step(){
        for(const snake of this.snakes){
            snake.next_step();
        }
    }

    check_valid(cell) {  // 检测目标位置是否合法：没有撞到两条蛇的身体和障碍物
        // 枚举所有障碍物，const是不许修改，ref可以修改
        for (const wall of this.walls) {
            // 撞障碍物了
            if (wall.r === cell.r && wall.c === cell.c)
                return false;
        }
        // 判断是否撞身体了，边界条件是可能蛇头快走到蛇尾的时候蛇尾收缩了
        for (const snake of this.snakes) {
            let k = snake.cells.length;
            // 当收缩时不能变长的时候，即蛇尾会前进的时候，此时不要判断
            if (!snake.check_tail_increasing()) {
                // 也就是不在snake.cells[k]这个位置上做判断,干脆k-1
                k -- ;
            }
            // 一般撞身体情况: 新增的cell与每条蛇的每个cell逐个判断
            for (let i = 0; i < k; i ++ ) {
                if (snake.cells[i].r === cell.r && snake.cells[i].c === cell.c)
                    return false;
            }
        }
        // 如果没有撞
        return true;
    }

    update(){
        // 正方形的大小 自适应变化
        this.update_size();

        // 判断蛇的下一步
        if(this.check_ready()){
            this.next_step();
        }

        this.render();//每帧渲染一次
    }
    render() {//渲染 
        const color_even = '#AAD751'//偶数颜色
        const color_odd = '#A2D149'//奇数颜色
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if ((r + c) % 2 == 0) {
                    this.ctx.fillStyle = color_even;
                } else {
                    this.ctx.fillStyle = color_odd;
                }
                // r行c列的图对应的canvas坐标是(c,r)[向右是x坐标系、向下是y坐标系]
                this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
            }
        }
        // this.ctx.fillStyle = 'green';
        // // 左上角坐标与边长
        // this.ctx.fillRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    }
}