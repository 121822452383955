<!-- 基础组件:地图画面 -->
<template>
    
    <div ref="parent" class="gamemap">
        <!-- 画布 -->
        <canvas ref="canvas" tabindex="0"></canvas>
    </div>
    <div style="padding-top: 20px;"></div>
    <div class="card" v-if="$store.state.record.is_record === false">
        <div class="card-header">
            <span style="font-size:130%">注意事项</span>
        </div>
        <div class="card-body" v-if="$store.state.pk.is_me">
            您是<font color="blue">蓝色玩家</font><br>
            请通过<font color="blue">WASD</font>来控制蛇向<font color="blue">上左下右</font>四个方向移动<br>
            <font color="blue">每回合计时5s</font>，若5s内没有操作则自动判输
        </div>
        <div class="card-body" v-else>
            您是<font color="red">红色玩家</font><br>
            请通过<font color="red">WASD</font>来控制蛇向<font color="red">上左下右</font>四个方向移动<br>
            <font color="red">每回合计时5s</font>，若5s内没有操作则自动判输
        </div>
    </div>
    <div class="card" v-if="$store.state.record.is_record === true">
        <div class="card-header">
            <span style="font-size:130%">A为<font color="blue">蓝色玩家</font>，B为<font color="red">红色玩家</font></span>
        </div>
    </div>
</template>
<script>
import { GameMap } from '../assets/scripts/GameMap'
import {onMounted, ref} from 'vue' //用于定义变量
import { useStore } from 'vuex';
export default {
    setup(){
        const store = useStore();
        let parent = ref(null); // 在js文件中的第二个变量
        let canvas = ref(null);

        // 组件挂载完之后需要执行什么操作
        onMounted(()=>{
            // 取得ctx画布, 画布的父元素
            // new GameMap(canvas.value.getContext('2d'), parent.value, store)
            store.commit("updateGameObject",
                new GameMap(canvas.value.getContext('2d'), parent.value, store)
            );
        });
        return{
            parent,
            canvas,
        }
    }
}
</script>
<style scoped>
div.gamemap{
    /* 100%相对于父元素来说的  */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card{
    text-align: center;
    background-color: rgba(202, 200, 200, 0.1);
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
}
</style>