<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card">
                    <div class="card-body">
                        <img :src="$store.state.user.photo" style="width:100%">
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card">
                    <div class="card-header">
                        <span style="font-size:130%">我的Bot</span>
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-primary float-end" @click="tomybot()">
                            更多
                        </button>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">名称</th>
                                    <th scope="col">创建时间</th>
                                    <th scope="col" style="padding-right: 17px;">操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bot in bots.slice(0, 3)" :key="bot.id">
                                    <td>{{ bot.title }}</td>
                                    <td>{{ bot.createtime }}</td>
                                    <td>
                                        <button type="button" class="btn btn-secondary" style="margin-right: 10px;" data-bs-toggle="modal" :data-bs-target="'#update-bot-btn-' + bot.id">查看</button>
                                        <!-- Modal -->
                                        <div class="modal fade" :id="'update-bot-btn-' + bot.id" tabindex="-1" aria-labelledby="update-bot-btnLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h1 class="modal-title fs-5" id="update-bot-btnLabel">查看 Bot </h1>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                    </div>
                                                    <!-- 更改example名称、添加placehold默认字段、修改所占行数rows -->
                                                    <div class="modal-body">
                                                        <!-- 请输入Bot名称 -->
                                                        <div class="mb-3">
                                                            <label for="add-bot-title" class="form-label" >名称</label>
                                                            <input v-model="bot.title" type="text" class="form-control" id="add-bot-title"
                                                                placeholder="请输入Bot名称" readonly>
                                                        </div>
                                                        <!-- 请输入Bot简介 -->
                                                        <div class="mb-3">
                                                            <label for="add-bot-description" class="form-label">简介</label>
                                                            <textarea v-model="bot.description" class="form-control" id="add-bot-description" rows="3"
                                                                placeholder="请输入Bot简介" readonly></textarea>
                                                        </div>
                                                        <!-- 请输入Bot代码 -->
                                                        <div class="mb-3">
                                                            <label for="add-bot-code" class="form-label">代码</label>
                                                            <textarea v-model="bot.content" class="form-control" id="add-bot-description" rows="10"
                                                                placeholder="请输入Bot简介" readonly></textarea>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div class="error-message">{{ bot.error_message }}</div>
                                                        <button type="button" class="btn btn-secondary"
                                                            data-bs-dismiss="modal">关闭</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <span style="font-size:130%">近期对局</span>
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-primary float-end" @click="torecord()">
                            更多
                        </button>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover" style="text-align: center;">
                        <thead>
                            <tr>
                                <th>A</th>
                                <th>B</th>
                                <th>对战结果</th>
                                <th>对战时间</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="record in records.slice(0, 6)" :key="record.record.id">
                                <td>
                                    <img :src="record.a_photo" alt="" class="record-user-photo">
                                    &nbsp;
                                    <span class="record-user-username">{{ record.a_username }}</span>
                                </td>
                                <td>
                                    <img :src="record.b_photo" alt="" class="record-user-photo">
                                    &nbsp;
                                    <span class="record-user-username">{{ record.b_username }}</span>
                                </td>
                                <td style="vertical-align:middle;">{{ record.result }}</td>
                                <td style="vertical-align:middle;">{{ record.record.createtime }}</td>
                                <td style="vertical-align:middle;">
                                    <button @click='open_record_content(record.record.id)' type="button" class="btn btn-secondary">查看录像</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import $ from 'jquery'
import { reactive } from 'vue'
import { Modal } from 'bootstrap/dist/js/bootstrap'
import router from '../router/index'

export default {
    name: "MainView",
    components: {

    },
    setup(){
        const store = useStore();
        // bots用于保存getlist返回的bot列表
        let bots = ref([]);
        let records = ref([]);
        // 定义一个Bot对象在Modal中显示
        const botadd = reactive({
            title:"",
            description:"",
            content:"",
            error_message:"",
        });

        // 定义函数
        const refresh_bots = () => {
            $.ajax({
                url:"https://www.hanfei.fun/api/user/bot/getlist/",
                type:"GET",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bots.value = resp;
                }
            })
        }
        // 调用函数
        refresh_bots();
        const pull_page = page => {
            $.ajax({
                url: "https://www.hanfei.fun/api/record/getlist/",
                data: {
                    page,
                },
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    records.value = resp.records;
                },
                error(resp) {
                    console.log(resp);
                }
            })
        }

        pull_page(1);
        const tomybot = () => {
            router.push({name:'user_bot_index'});
        }
        const torecord = () => {
            router.push({name:'record_index'});
        }
        const add_bot = () => {
            botadd.error_message = "";
            $.ajax({
                // 从App.vue中复制之前测试用的代码
                url: "https://www.hanfei.fun/api/user/bot/add/",
                type: "post",
                data: {
                    title: botadd.title,
                    description: botadd.description,
                    content: botadd.content,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    // console.log(resp);
                    if (resp.error_message === "success") {
                        botadd.title = "";
                        botadd.description = "";
                        botadd.content = "";
                        // 关闭悬浮窗，此处为Modal的id
                        // <!-- Modal -->
                        // <div class="modal fade" id="add-bot-btn"
                        Modal.getInstance("#add-bot-btn").hide();
                        refresh_bots();
                    } else {
                        botadd.error_message = resp.error_message;
                    }
                }
            })
        }

        const stringTo2D = map => {
            let g = [];
            for (let i = 0, k = 0; i < 13; i ++ ) {
                let line = [];
                for (let j = 0; j < 14; j ++, k ++ ) {
                    if (map[k] === '0') line.push(0);
                    else line.push(1);
                }
                g.push(line);
            }
            return g;
        }

        const open_record_content = recordId => {
            for (const record of records.value) {
                if (record.record.id === recordId) {
                    // 更新状态
                    store.commit("updateIsRecord", true);
                    // 保存一下Game信息
                    store.commit("updateGame", {
                        map: stringTo2D(record.record.map),
                        a_id: record.record.aid,
                        a_sx: record.record.asx,
                        a_sy: record.record.asy,
                        b_id: record.record.bid,
                        b_sx: record.record.bsx,
                        b_sy: record.record.bsy,
                    });
                    store.commit("updateSteps", {
                        a_steps: record.record.asteps,
                        b_steps: record.record.bsteps,
                    });
                    store.commit("updateRecordLoser", record.record.loser);
                    // 跳转到查看录像的界面
                    router.push({
                        name: "record_content",
                        params: {
                            recordId
                        }
                    })
                    break;
                }
            }
        }

        return{
            bots,botadd,add_bot,tomybot,records,open_record_content,torecord
        }
    }
}
</script>

<style scoped>
div.card{
    margin-top: 20px;
}
.fade{
    --bs-modal-width: 700px;
}
th{
    vertical-align:middle;
    text-align: center;
}
td{
    vertical-align:middle;
    text-align: center;
}
img.record-user-photo {
    width: 6vh;
    border-radius: 50%;
}
</style>