import $ from 'jquery'

export default ({
    state: {
        id:"",
        username:"",
        photo:"",
        token:"",
        is_login:false,
        pulling_info:true,
    },
    getters:{
    },
    mutations: {
        updateUser(state, user){
            state.id = user.id;
            state.username = user.username;
            state.photo = user.photo;
            state.is_login = user.is_login;
        },
        updateToken(state, token){
            state.token = token;
        },
        logout(state){
            state.id = "";
            state.username = "";
            state.photo = "";
            state.token = "";
            state.is_login = false;
        },
        updatePullingInfo(state, pulling_info){
            state.pulling_info = pulling_info;
        }
    },
    actions: {
        login(context, data){
            $.ajax({
                url:"https://www.hanfei.fun/api/user/account/token/",
                type:"post",
                data:{
                    username:data.username,
                    password:data.password,
                },
                success(resp){
                    if(resp.error_message === "success"){
                        localStorage.setItem("jwt_token", resp.token);
                        context.commit("updateToken",resp.token);
                        data.success(resp);
                    }else{
                        data.error(resp);
                    }
                },
                error(resp){
                    data.error(resp);
                }
            })
        },
        getinfo(context, data){
            $.ajax({
                url:"https://www.hanfei.fun/api/user/account/info/",
                type:"get",
                headers:{
                    Authorization:"Bearer " + context.state.token, // token存储在state中
                },
                success(resp){
                    if(resp.error_message === "success"){
                        context.commit("updateUser",{
                            ...resp,//解析resp的内容
                            is_login:true,
                        });
                        data.success(resp);
                    }else{
                        data.error(resp);
                    }
                },
                error(resp){
                    data.error(resp);
                }
            })
        },
        logout(context){
            localStorage.removeItem("jwt_token")
            context.commit("logout");
        },
        updatePullingInfo(state,pulling_info){
            state.pulling_info = pulling_info;
        }
    },
    modules: {
    }
})